import { Link } from "react-router-dom";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import "./commercial.css"
import { MagasinContext } from "../../../Context/MagasinContext";
import { useContext, useEffect, useState } from "react";
import { CommercialInfo } from "../../../Modeles/Commercial";
import AfficheCommercial from "../../../Services/Magasin/AfficheCommercial";

export interface ProductType {
  product: CommercialInfo[],
  messageErros: string,
}

export default function Commercial() {
  const magasinContext = useContext(MagasinContext);
  const [search, setSearch] = useState("");
  const MagasinId = localStorage.getItem('MagasinId');
  const id = MagasinId || magasinContext.id?.id;
  const [state, setState] = useState<ProductType>({
    product: [] as CommercialInfo[],
    messageErros: "accune produit",
  });

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setState({ ...state });
    AfficheCommercial(id).GetCommercial()
      .then((res) => setState({ ...state, product: res.data }))
      .catch(msg => setState({ ...state, product: msg.messageErros }));
  }, [id]);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const { product, messageErros } = state;

  // Filter products based on search term
  const filteredProducts = product.filter((pro) => {
    const searchTerm = search.trim().toLowerCase().replace(/\s+/g, '');
    const nom = pro.Nom ? pro.Nom.toLowerCase().replace(/\s+/g, '') : '';
    const prenom = pro.prenom ? pro.prenom.toLowerCase().replace(/\s+/g, '') : '';
    return nom.includes(searchTerm) || prenom.includes(searchTerm);
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <SideBareMagasin />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      <div className="container containerC mt-5">
        <div className="row height">
          <div className="col-md-6">
            <div className="form formCommercial">
              <i className="fa fa-search" />
              <input 
                type="text" 
                className="form-control form-input" 
                placeholder="Recherchez un commercial, ..." 
                onChange={handleSearch} 
                value={search}
              />
              <Link to={`/magasins/${id}/commercials/add`}><button className="btnAjouteC">Ajouter un Commercial</button></Link>
            </div>
          </div>
        </div>

        <div className="tableInfos">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="ncom">Prénom</th>
                <th scope="col">Nom</th>
                <th scope="col">Email</th>
                <th scope="col">mod de pass</th>
                <th scope="col">Vendu</th>
                <th scope="col">En cours</th>
                <th scope="col">Annulé</th>
                <th scope="col">Action</th>
              </tr>
            </thead>

            <tbody>
              {currentItems && currentItems.length > 0 ? currentItems.map((pro, index) => (
                <tr key={index}>
                  <td>{pro.prenom}</td>
                  <td>{pro.Nom}</td>
                  <td>{pro.email}</td>
                  <td>{pro.password}</td>
                  <td style={{color:"#215e21"}}>0<i className="bi bi-arrow-up"></i></td>
                  <td style={{color:"rgb(183, 146, 4)"}}>0</td>
                  <td style={{color:"#b01d1d"}}>0<i className="bi bi-arrow-down"></i></td>
                  <td><Link className="btn btn-success" to={`/magasins/${id}/commercials/${pro.id}`}>Gérer</Link></td>
                  
                </tr>
              )) : (
                <tr><td colSpan={7}><h5><i className="bi bi-info"></i> Accune Commercial</h5></td></tr>
              )}
            </tbody>
          </table>

          {pageNumbers.length > 2 && (
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
                    Précédent
                  </button>
                </li>
                {pageNumbers.map((number) => (
                  <li className="page-item" key={number}>
                    <button className={`page-link ${currentPage === number ? 'active-Button' : ''} page-number`} style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(number)}>{number}</button>
                  </li>
                ))}
                <li className="page-item">
                  <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(filteredProducts.length / itemsPerPage)}>
                    Suivant
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  );
}
