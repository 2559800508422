import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebare.css";
import Logo from "../IMG/Logo.png";
import GetNotificationAdminNoRead from "../../../Services/Admin/GetNotificationAdminNoRead";
import { NotificationMagasinType } from "../../../Modeles/NotificationModel";
import { NotificationType } from "../notification/NotificationAdmin";
import DemandesAdmin from "../../../Services/Admin/DemandesAdmin";
import SideBarMobile from "./SideBarMobile";

export default function Sidebare() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [state, setState] = useState<any>("");
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const [stateNotification, setStateNotification] = useState<NotificationType>({
    Notifications: [] as NotificationMagasinType[],
    messageErrosNotif: "aucune Notification",
  });

  // Fetch notifications

  useEffect(() => {
    setStateNotification({ ...stateNotification });
    GetNotificationAdminNoRead()
      .GetNotification()
      .then((res) =>
        setStateNotification({ ...stateNotification, Notifications: res.data })
      )
      .catch((msg) =>
        setStateNotification({
          ...stateNotification,
          Notifications: msg.messageErrosNotif,
        })
      );
  }, []);

  const { Notifications, messageErrosNotif } = stateNotification;

  // Track active menu item
  useEffect(() => {
    const path = location.pathname;
    setActiveItem(path);
  }, [location.pathname]);

  // Fetch demandes
  useEffect(() => {
    DemandesAdmin()
      .GetCommande()
      .then((res) => setState({ ...state, demande: res.data }))
      .catch((msg) =>
        setState({ ...state, demande: msg.messageErros })
      );
  }, []);

  const { demande, messageErros } = state;

  // Track screen width

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Render logic based on screen size

  if (screenWidth <= 450) {
    return <SideBarMobile />;
  }

  return (

    <>
      {/* Sidebar for admin */}

      <body>
        <section className="sidebar">
          <a href="#" className="brand">
            <img className="mt-3" src={Logo} />
          </a>
          <br />
          <ul className="side-menu top">
          <li className={
  activeItem === "/home" || 
  (activeItem && /^\/articles\/[a-zA-Z0-9]+$/.test(activeItem)) || 
  (activeItem && /^\/articles\/[a-zA-Z0-9]+\/edit$/.test(activeItem)) 
    ? "active" 
    : ""
}>

              <Link className="active" to="/home">
                <a href="#">
                  <i className="bi bi-menu-button-wide-fill" />
                </a>
              </Link>
            </li>
            <li className={activeItem === "/articles" ?  "active"  : "" || activeItem==="/message" || activeItem === "/articles/categories/add" ?  "active"  : ""}>
              <Link to="/articles">
                <a href="#">
                  <i className="bi bi-box-seam" />
                </a>
              </Link>
            </li>
            <li className={activeItem === "/magasin" ? "active" : "" || activeItem === "/magasin/add" ? "active"  : "" || activeItem && activeItem.startsWith("/magasin/") && activeItem.endsWith("/edit") ? "active"  : ""}>
              <Link to="/magasin">
                <a href="#">
                  <i className="bi bi-shop" />
                </a>
              </Link>
            </li>
            <li className={activeItem === "/historiques" ? "active" : ""}>
              <Link to="/historiques">
                <a href="#">
                  <i className="bi bi-clock"></i>
                </a>
              </Link>
            </li>
            <li className={activeItem === "/demandes" ? "active" : ""}>
              <Link to="/demandes">
                <a href="#">
                <i className="bi bi-envelope"></i>
                {
                  demande && demande.length>0?

                <span className="countNotif">{demande.length}</span>
                :""  
                }
                </a>
              </Link>
            </li>
          </ul>
          <div className="menu-down-admin mt-5">
          <div className="hr">
            <hr />
          </div>
            <ul className="side-menu top ">
            <li className={activeItem === "/notifications" ? "active" : "" }>
              <Link to={"/notification"}>

                <a href="">
              <i className="bi bi-bell"/>
              {
                  Notifications && Notifications.length>0?

                <span className="countNotif">{Notifications.length}</span>
                :""  
                }
                      </a>
                </Link>
              </li>
              <li className={activeItem === "/Paramétres" ? "active" : ""}>

                <Link to="/Paramétres">
                  <a href="#">
                    <i className="bi bi-gear" />
                  </a>
                </Link>
              </li>
            </ul>
            <ul className="logout-admin">
              <li className={activeItem === "/logout" ? "active" : ""}>
                <Link to="/logout" className="logout">
                  <a href="#">
                    <i className="bi bi-box-arrow-left" />
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </body>
      
      <div className="container">
        <Link className=" icon-assi" to={"/assistance"}><i className="bi bi-headset"></i></Link>
      
      </div>
    </>
  );
}
