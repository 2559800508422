import { useEffect, useState } from "react";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { Link } from "react-router-dom";
import 'swiper/css/free-mode';
import "./AllArticleMobil.css"
import 'swiper/css/autoplay';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import SideBarMobile from "../Sidbare/SideBarMobile";

export default function AllArticleMobile() {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<ArticleInfo[]>([]);
  const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const [totalPages, setTotalPages] = useState(1);

  const fetchProducts = (pageNumber: number) => {
    setLoading(true);
    AfficheProductsService().getProductPagination(pageNumber)
      .then((res: any) => {
        setProducts(res.data.data);
        setAlldata(res.data.alldata);
        setTotalPages(res.data.last_page);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching products: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const filteredProducts = alldata.filter((pro: ArticleInfo) => {
    const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];

    return (
      pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
      pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
      refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) ||
      pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <>
      <SideBarMobile />
      <div className="container">
        <div className="search-part position-relative mb-3">
          <i className="bi bi-search position-absolute" style={{ top: '20%', left: '10px', transform: 'translateY(-50%)', fontSize: '16px', color: '#6c757d' }}></i>
          <input
            onChange={handleSearch}
            className="form-control ps-5 pe-5"
            type="text"
            placeholder="Recherchez un produit, une référence..."
            aria-label="search input"
          />
          <i className="bi bi-sliders position-absolute" style={{ top: '20%', right: '10px', transform: 'translateY(-50%)', fontSize: '16px', color: '#6c757d' }}></i>
          <Link to="/articles/categories/add" type="button" className="w-100 mt-3 btn btn-sm">
            Ajouter un produit
          </Link>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status"></div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : currentRecords.length > 0 ? (
          <div className="container mobile-article-card">
            <div className="produit-available">
            <p>Equipement</p>
            <Link to="/articles" className="vrp">Voir plus<i className="bi bi-arrow-right-short"></i></Link>
            </div>
            <div className="row" style={{ width: "800px", marginTop: "-20%", marginLeft: "-70%", transform: 'scale(0.5)' }}>
              <Swiper
                freeMode={true}
                grabCursor={true}
                className="container myswiper"
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  380: {
                    slidesPerView: 2,
                    spaceBetween: 80,
                  },
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode]}
              >
                {currentRecords.map((pro) => (
                  <SwiperSlide key={pro.id}>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
                        <div className="u-card">
                          <div className="u-off">
                            <span className="off">25% off</span>
                          </div>
                          <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
                          <div className="u-card-icons">
                            <i className="bi bi-suit-heart u-heart"></i>
                            <i className="bi bi-cart u-card-shopping"></i>
                          </div>
                          <div className="u-km">
                            <p>À 10 Km de chez vous</p>
                          </div>
                          <div className="u-star">
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                          </div>
                          <div className="u-card-title">{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
                          <p className="u-card-ref-ar mb-2">Ref : {pro.RefArticle[0]}</p>
                          <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        ) : (
          <div className="no-produit">
            <i className="bi bi-emoji-neutral"></i>
            <br />
            <p>Malheureusement, on n‘a pas ce produit pour l’instant.</p>
            <br />
          </div>
        )}
        <div className="pagination mb-5">
          <button
            style={{ borderRadius: "100%" }}
            className="page-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="bi bi-arrow-left"></i>
          </button>
          <span className="page-info">Page {currentPage} of {Math.ceil(filteredProducts.length / recordsPerPage)}</span>
          <button
            style={{ borderRadius: "100%" }}
            className="page-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * recordsPerPage >= filteredProducts.length}
          >
            <i className="bi bi-arrow-right"></i>
          </button>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status"></div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : currentRecords.length > 0 ? (
          <div className="container mobile-article-card" style={{marginTop:"-60%"}}>
            <div className="produit-available">
              <p>Lubrifiants</p>
              <Link to="/articles" className="vrp">Voir plus<i className="bi bi-arrow-right-short"></i></Link>
            </div>
            <div className="row" style={{ width: "800px", marginTop: "-20%", marginLeft: "-70%", transform: 'scale(0.5)' }}>
              <Swiper
                freeMode={true}
                grabCursor={true}
                className="container myswiper"
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  380: {
                    slidesPerView: 2,
                    spaceBetween: 80,
                  },
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode]}
              >
                {currentRecords.map((pro) => (
                  <SwiperSlide key={pro.id}>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
                        <div className="u-card">
                          <div className="u-off">
                            <span className="off">25% off</span>
                          </div>
                          <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
                          <div className="u-card-icons">
                            <i className="bi bi-suit-heart u-heart"></i>
                            <i className="bi bi-cart u-card-shopping"></i>
                          </div>
                          <div className="u-km">
                            <p>À 10 Km de chez vous</p>
                          </div>
                          <div className="u-star">
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                            <i className="bi bi-star-fill u-i-star"></i>
                          </div>
                          <div className="u-card-title">{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
                          <p className="u-card-ref-ar mb-2">Ref : {pro.RefArticle[0]}</p>
                          <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        ) : (
          <div className="no-produit">
            <i className="bi bi-emoji-neutral"></i>
            <br />
            <p>Malheureusement, on n‘a pas ce produit pour l’instant.</p>
            <br />
          </div>
        )}
      </div>
    </>
  );
}
