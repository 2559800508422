import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

const ProtectedAdmin: React.FC = () => {
  const roleAdmin = "admin"; 
  const savedNameRole = localStorage.getItem('role');


  if (savedNameRole == roleAdmin) {
    return <Navigate to="/404" replace />;
  }

  return <Outlet />; 
};

export default ProtectedAdmin;