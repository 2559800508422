import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import Sidebare from '../Sidbare/Sidebare';
import Strip from '../../../components/Strip';
import AllArticleMobile from '../mobileAdmin/AllArticleMobile';

export default function AllProduit() {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<ArticleInfo[]>([]);
  const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  // Fetch products based on the current page
  const fetchProducts = (pageNumber: number) => {
    setLoading(true);
    AfficheProductsService().getProductPagination(pageNumber)
      .then((res: any) => {
        setProducts(res.data.data);
        setAlldata(res.data.alldata); // Save all data for searching
        setTotalPages(res.data.last_page);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching products: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // If screen width is small, render the mobile version
  if (screenWidth <= 450) {
    return <AllArticleMobile />;
  }

  // Handle search input change
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to the first page when search is changed
  };

  // Filter products based on search
  const filteredProducts = alldata.filter((pro: ArticleInfo) => {
    const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];
    return (
      pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
      pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
      refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) ||
      pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

  const renderProducts = () => {
    if (paginatedProducts.length > 0) {
      return paginatedProducts.map((pro: ArticleInfo) => (
        <div className="col-lg-3 col-md-6 col-12 mt-3" key={pro.IdArticle}>
          <Link className="gotoshow" to={`/articles/${pro.RefArticle[0]}`}>
            <div className="u-card">
              <div className="u-off">
                <span className='off'>25% off</span>
              </div>
              <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
              <div className="u-card-icons">
                <i className="bi bi-suit-heart u-heart"></i>
                <i className="bi bi-cart u-card-shopping"></i>
              </div>
              <div className="u-km">
                <p>À 10 Km de chez vous</p>
              </div>
              <div className="u-star">
                {[...Array(5)].map((_, index) => (
                  <i key={index} className="bi bi-star-fill u-i-star"></i>
                ))}
              </div>
              <div className="u-card-title">{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
              <p className="u-card-ref-ar">Ref : {pro.RefArticle[0]}</p>
              <p className="u-card-lib-ar">LibelleFamArticle: <span>{pro.LibelleFamArticle || "N/A"}</span></p>
              <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
            </div>
          </Link>
        </div>
      ));
    } else {
      return (
        <div className="no-produit">
          <i className="bi bi-emoji-neutral"></i>
          <br />
          <p>Malheureusement, on n‘a pas ce produit pour l’instant.</p>
          <br />
          <Link
            to={"https://api.whatsapp.com/send?phone=212661718081"}
            target="_blank"
            className="botton-remplir"
          >
            <button>
              <i className="bi bi-whatsapp"></i>Contactez-nous
            </button>
          </Link>
        </div>
      );
    }
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <>
      <Sidebare />
      <Strip />
      <div className='container'>
        <div className="container_div mt-5 rechAA" style={{ width: '100vw' }}>
          <div style={{ display: 'flex', marginLeft: '-3%', alignItems: "center" }}>
            <div style={{ width: '65vw' }}>
              <div className="position-relative">
                <i className="bi bi-search position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                <input
                  type="text"
                  onChange={handleSearch}
                  className="form-control form-input pl-4"
                  placeholder="Recherch un produit, ref .."
                />
                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
              </div>
            </div>
            <div className="ml-4" style={{ marginLeft: '30px' }}>
              <Link to="/articles/categories/add"><button className="btnAjoutee">Ajouter Produit</button></Link>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status"></div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : (
          <>
            <div className="row">
              {renderProducts()}
            </div>
            <div className="pagination mb-5">
              <button
                style={{ borderRadius: "100%" }}
                className="page-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <i className="bi bi-arrow-left"></i>
              </button>
              <span className="page-info">Page {currentPage} of {totalPages}</span>
              <button
                style={{ borderRadius: "100%" }}
                className="page-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
