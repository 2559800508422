import React, { useEffect, useState } from 'react';
import Sidebare from "../Sidbare/Sidebare";
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode,Navigation } from 'swiper/modules';
import "./Home.css"
// Import Swiper styles
import 'swiper/swiper-bundle.css';

import "bootstrap/dist/css/bootstrap.min.css";
import ImageProduit2 from "../IMG/2.png";
import logoMagasin from "../IMG/etablisement.png";
import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import AfficheMagasinsService from "../../../Services/Magasin/AfficheMagasinsService";
import { MagasinInfo } from "../../../Modeles/MagasinModel";
import { NotificationMagasinType } from '../../../Modeles/NotificationModel';
import GetNotificationAdmin from '../../../Services/Admin/GetNotificationAdmin';
import Strip from '../../../components/Strip';
import SideBarMobile from '../Sidbare/SideBarMobile';
import HomeAdminMobile from '../mobileAdmin/HomeAdminMobile';
export interface ProductType {
  product: ArticleInfo[],
  messageErros: string,
}
export interface ProductTypeMagasins {
  magasins: MagasinInfo[],
  messageErrosMagasin: string,
}
export default function Home(){
  // ------------- State for Magasins -----------------
  const [states , setStates] = useState<ProductTypeMagasins>({
    magasins: [] as MagasinInfo[],
    messageErrosMagasin : "Il n'y a pas de magasin",
  });
  const categories = ["Pièces automobiles", "Constructeur", "Maintenance et réparation", "Outils industriels", "Equipements", "Lubrifiants", "Huiles"];
  const [selectedCategory, setSelectedCategory] = useState<string | null>("Tous");
  const handleCheckboxChange = (category: string) => {
    setSelectedCategory((prev) => (prev === category ? null : category)); // Toggle selection
  };
  const [search, setSearch] = useState("");
  const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const [products, setProducts] = useState<ArticleInfo[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const fetchProducts = (pageNumber: number) => {
    setLoading(true);
    AfficheProductsService().getProductPagination(pageNumber)
      .then((res: any) => {
        setProducts(res.data.data);
        setAlldata(res.data.alldata);
        setTotalPages(res.data.last_page);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching products: ", err);
        setLoading(false);
      });
  };
  console.log("page",totalPages);
  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);
  useEffect(() => {
    AfficheMagasinsService().getMagasin()
      .then((res) => setStates({ ...states, magasins: res.data }))
      .catch(msg => setStates({ ...states, messageErrosMagasin: msg.messageErrosMagasin }));
  }, []);  
  const { magasins, messageErrosMagasin } = states;
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =setSearch(e.target.value.trim());

}; 
  // ------------- State for Products ------------------
  const [state, setState] = useState<ProductType>({
    product: [] as ArticleInfo[],
    messageErros : "Il n'y a pas de produit",
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    AfficheProductsService().getProduct()
      .then((res) => {
        setState({ ...state, product: res.data.data});
        setLoading(false);
      })
      .catch((msg) => {
        setState({ ...state, messageErros: msg.messageErros });
        setLoading(false);
      });
  }, []);
  const filteredProducts = (alldata || []).filter((pro: ArticleInfo) => {
    const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];
    return (
      pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
      pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
      refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) ||
      pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
    );
  });
  const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const { product, messageErros } = state;
  console.log("test produit",product);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (screenWidth <= 450) {
    return     <>
    <SideBarMobile/>
    <div className="bienvenu">
    <h5>Bienvenue !</h5>
    <p>Vous pouvez gérer votre stock en simplicité </p>
  </div>
  <div className="container-fluid ProductSlide mt-1">

      {/* Loader */}
      {loading ? (
<div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
  <div className="spinner-border custom-spinner" role="status"></div>
  <span className="loader-charg">Chargement</span>
</div>
) : currentRecords.length > 0 ? (
<div className="container mobile-article-card">
  <div className="produit-available">
      <p>Vous avez + de {alldata && alldata.length >0 ? alldata.length :"0"} Produits</p>
      <Link to={"/articles"} className="vrp">Voir plus<i className="bi bi-arrow-right-short"></i></Link>
  </div>
      <div className="row" style={{width:"800px",marginTop:"-20%" , marginLeft:"-70%",transform:'scale(0.5)'}}>

  <Swiper
  freeMode={true}
    grabCursor={true}
    className="container myswiper"
    breakpoints={{
      0: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      380: {
        slidesPerView: 2,
        spaceBetween: 80,
      },
    }}
    autoplay={{
      delay: 2000, 
      disableOnInteraction: false,
    }}
    modules={[Autoplay, FreeMode]}
    >
    {currentRecords.map((pro) => (
      <SwiperSlide>
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4 card-product-parent" key={pro.IdArticle}>
        <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
        <div className="u-card">
          <div className="u-off">
            <span className='off' >
              25% off
            </span>
          </div>
          <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
          <div className="u-card-icons">
    <i className="bi bi-suit-heart u-heart"></i>
    <i className="bi bi-cart u-card-shopping"></i>
    </div>
          <div className="u-km">
            <p>À 10 Km de chez vous</p>
          </div>
          <div className="u-star">
            <i className="bi bi-star-fill u-i-star"></i>
            <i className="bi bi-star-fill u-i-star"></i>
            <i className="bi bi-star-fill u-i-star"></i>
            <i className="bi bi-star-fill u-i-star"></i>
            <i className="bi bi-star-fill u-i-star"></i>
          </div>
          <div className="u-card-title" >{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
          <p className="u-card-ref-ar mb-2" >Ref : {pro.RefArticle[0]}</p>
          <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
        </div>
        </Link>
      </div>
          </SwiperSlide>
    )
    )
    }
  </Swiper>
  </div>
</div>
) : (
<div className="no-produit">
        <i className="bi bi-emoji-neutral"></i>
        <br />
        <p>
          Malheureusement, on n‘a pas ce produit pour l’instant.
        </p>
        <br />
      </div>
)}
<div style={{marginTop:"-20%"}}>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color:"#FFC400" }}>
          <div className="spinner-border custom-spinner" role="status">
          </div>
          <span className="loader-charg">Chargement</span>
        </div>
      ) : (

          <>
          <div className="magasin-available">

      <p>Vous avez + de 20 magasins</p>
  <Link to={"/magasin"} className="vrp">Voir plus<i className="bi bi-arrow-right-short"></i></Link>
  </div>
      <div className="mt-3 position-relative container-fluid ProductSlide w-75" style={{width:"100px" ,marginLeft:"-10%",transform:'scale(0.8)'}}>
          
        <Swiper
        style={{width:"600px"}}
          freeMode={true}
          grabCursor={true}
          className="container myswiper"
          breakpoints={{
            0: { slidesPerView: 2, spaceBetween: 10 },
            380: { slidesPerView: 2, spaceBetween: 10 },
          }}
          autoplay={{
            delay: 2300, 
            disableOnInteraction: false,
          }}
          navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
          modules={[Autoplay, FreeMode,Navigation]}
        >
          {magasins && magasins.length > 0 ?
            magasins.map(mag => (
              <>
              <SwiperSlide key={mag.IdMagasin}>
                <Link  style={{textDecoration:'none'}} className="" to={`/magasin/${mag.IdMagasin}/edit`}>
                <div className="card-u-magasin-affiche">
                <h2 className='u-n-m'>{mag.NomMagasin}</h2>
              <p className='u-para-mag'><i className="bi icoloc bi-geo-alt-fill"></i >{mag.adresse_de_siège && mag.adresse_de_siège?.split(' ')[0]}
                <h6 className='u-dsc-m'>{mag.adresse_de_siège && mag.adresse_de_siège?.split(',')[1]?.trim() || 'casablanca'}</h6>

              </p>
                </div>
                </Link>
              </SwiperSlide>
                
             
            </>
            )) : <h5 className="text-center">{messageErrosMagasin}</h5>}
        </Swiper>
            <div style={{color:"transparent",marginLeft:"1%"}}  className="swiper-button-prev">
              <i className="bi bi-chevron-left"></i>
            </div>
            <div style={{color:"transparent",marginRight:"-78%"}}  className="swiper-button-next">
              <i style={{color:"white"}}  className="bi bi-chevron-right"></i>
            </div>
      </div>
      </>
      )}
      </div>
      </div>
      </>
  }
  return (
    <div>

  <Strip/>
    <div className="title-app">
            <h3>Explorez nos solutions</h3>
        {/* <Link to ={"/visteurs/contactez-nous"}><span className="contact-icon"> Contactez nous <i className="bi bi-chat-square-text-fill"></i></span></Link> */}
        </div>
      <Sidebare />
      <div className="home container mt-3" style={{marginLeft:"19%", scale:"0.9"}}>
        <div className="part-search-trier">
          <div className="search-new">
          <div className=""style={{ width: '35vw' }}>
                <i className="bi bi-search position-absolute" style={{fontSize: "1.4rem", transform: 'translateY(-50%)',marginLeft:"38%",marginTop:"1.7%" }} />
                <input
                  type="text"
                  onChange={handleSearch}
                  className="form-control w-100"
                  placeholder="Recherch un produit, ref .."
                />
              </div>
          </div>
          <div className="trier-par">
            Trier par 
          <select name="" id="" className='mx-2'>
            <option value="">meilleur ventes</option>
            <option value="">les plus vendus </option>
          </select>
          </div>
          </div>
          <div className="filter-result mt-3">
          <h6>
  filter actif :{" "}
  <b style={{ cursor: "pointer" }}>
    {selectedCategory !== "" ? (
      <>
        {selectedCategory}{" "}
        <i
          className="bi bi-x-lg"
          onClick={() => setSelectedCategory("Tous")}
          style={{ cursor: "pointer" }}
        ></i>
      </>
    ) :
      <b>tous</b>
    }
  </b>
</h6>            
<h6><b>{alldata && alldata.length >0 ? alldata.length :"0"}</b> resultas trouvés</h6>
          </div>
          </div>
      <br /><br />
      <div className="content-visiteure">

      <div className="categorie" style={{marginTop:"-20%", marginLeft:"6%"}}>
      <h5 className="mb-3">CATEGORIE</h5>
      {categories.map((category, index) => (
        <div className="category-checkbox" key={index}>
          <label>
            <input
              type="checkbox"
              name="category"
              value={category}
              checked={selectedCategory === category}
              onChange={() => handleCheckboxChange(category)}
            />
            <span className="checkbox-custom"></span>
            <span className="checkbox-label">{category}</span>
          </label>
        </div>
      ))}
    </div>
    
      {/* ----------------------------------- PRODUIT SLIDE ------------------------------------ */}
      <div className="container-fluid ProductSlide mt-1">
        <div className=" pavp">
          <div>
          </div>
          <div>
          </div>
        </div>
        {loading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
    <div className="spinner-border custom-spinner" role="status"></div>
    <span className="loader-charg">Chargement</span>
  </div>
) : currentRecords.length > 0 ? (
  <div className="container">
    <div className="row" style={{marginLeft:"30px"}}>
      {currentRecords.map((pro) => (
        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={pro.IdArticle}>
          <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
          <div className="u-card">
            <div className="u-off">
              <span className='off'>
                25% off
              </span>
            </div>
            <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
            <div className="u-card-icons">
      <i className="bi bi-suit-heart u-heart"></i>
      <i className="bi bi-cart u-card-shopping"></i>
      </div>
            <div className="u-km">
              <p>À 10 Km de chez vous</p>
            </div>
            <div className="u-star">
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
            </div>
            <div className="u-card-title">{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
            <p className="u-card-ref-ar">Ref : {pro.RefArticle[0]}</p>
            <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
          </div>
          </Link>
        </div>
      )
      )
      }
      
    </div>
    
    {/* Pagination */}
    <div className="pagination d-flex justify-content-center">
      <button
        style={{ borderRadius: "100%" }}
        className="page-button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
      <span className="page-info">Page {currentPage} of {Math.ceil(filteredProducts.length / recordsPerPage)}</span>
      <button
        style={{ borderRadius: "100%" }}
        className="page-button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage * recordsPerPage >= filteredProducts.length}
      >
        <i className="bi bi-arrow-right"></i>
      </button>
    </div>
  </div>
) : (
  <div className="no-produit">
          <i className="bi bi-emoji-neutral"></i>
          <br />
          <p>
            Malheureusement, on n‘a pas ce produit pour l’instant.
          </p>
          <br />
        </div>
)}

  </div>
      </div>
  <br /><br />

        {/*----------------------------------- Magasin SLIDE --------------------------------------- */}
        <div className="d-flex w-75 mx-auto justify-content-between pavp">
          <div className="container d-flex justify-content-center align-items-center text-center">
          <p  style={{fontSize:'1.5rem', fontFamily:"fantasy", color:"black", fontWeight:"500"}} className="pMagazin">
          Vous avez +20 magasins</p>
          </div>
          {/* <div>
            <Link to={"/magasin"} className="mx-5">Voir Plus <i className="bi bi-arrow-right-short" /></Link>
          </div> */}
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color:"#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status">
            </div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : (
        <div className="mt-3 position-relative container-fluid ProductSlide w-75" style={{marginLeft:'16%'}}>
          <Swiper
            freeMode={true}
            grabCursor={true}
            className="container myswiper"
            breakpoints={{
              0: { slidesPerView: 1, spaceBetween: 10 },
              480: { slidesPerView: 2, spaceBetween: 10 },
              700: { slidesPerView: 4, spaceBetween: 10 }
            }}
            autoplay={{
              delay: 2300, 
              disableOnInteraction: false,
            }}
            navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
            modules={[Autoplay, FreeMode,Navigation]}
          >
            {magasins && magasins.length > 0 ?
              magasins.map(mag => (
                <>
                <SwiperSlide key={mag.IdMagasin}>
                  <Link  style={{textDecoration:'none'}} className="" to={`/magasin/${mag.IdMagasin}/edit`}>
                  <div className="card-u-magasin-affiche">
                  <h2 className='u-n-m'>{mag.NomMagasin}</h2>
                <p className='u-para-mag'><i className="bi icoloc bi-geo-alt-fill"></i >{mag.adresse_de_siège &&  mag.adresse_de_siège.split(',')[0]?.trim() || 'casablanca'}
                  <h6 className='u-dsc-m'>{mag.adresse_de_siège &&  mag.adresse_de_siège.split(',')[1]?.trim() || 'casablanca'}</h6>

                </p>
                  </div>
                  </Link>
                </SwiperSlide>
                  
               
              </>
              )) : <h5 className="text-center">{messageErrosMagasin}</h5>}
          </Swiper>
              <div style={{color:"transparent"}}  className="swiper-button-prev">
                <i className="bi bi-chevron-left"></i>
              </div>
              <div style={{color:"transparent"}}  className="swiper-button-next">
                <i style={{color:"white"}}  className="bi bi-chevron-right"></i>
              </div>
        </div>
        )}
          </div>
      
    
  );
}

