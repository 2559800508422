import { Link } from "react-router-dom";
import "./notification.css";
import Sidebare from "../Sidbare/Sidebare";
import { NotificationMagasinType } from "../../../Modeles/NotificationModel";
import { useEffect, useState } from "react";
import GetNotificationAdmin from "../../../Services/Admin/GetNotificationAdmin";
import Strip from "../../../components/Strip";
import SideBarMobile from "../Sidbare/SideBarMobile";

export interface NotificationType {
  Notifications: NotificationMagasinType[],
  messageErrosNotif: string,
}

export default function NotificationAdmin() {
  // ----------------------- State -----------------------
  const [stateNotification, setStateNotification] = useState<NotificationType>({
    Notifications: [] as NotificationMagasinType[],
    messageErrosNotif: "Aucune Notification",
  });
  const [loading, setLoading] = useState<boolean>(true); // State for loader
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  // ----------------------- Fetch Notifications -----------------------
  useEffect(() => {
    GetNotificationAdmin()
      .GetNotification()
      .then((res) => {
        setStateNotification({ ...stateNotification, Notifications: res.data });
        setLoading(false); // Stop loading when data is fetched
      })
      .catch((error) => {
        setStateNotification({ ...stateNotification, messageErrosNotif: "Aucune Notification" });
        setLoading(false); // Stop loading even if there's an error
      });
  }, []);

  const { Notifications, messageErrosNotif } = stateNotification;

  // ----------------------- Mobile View -----------------------
  if (screenWidth <= 450) {
    return (
      <>
        <Sidebare />
        <div className="container container-notificatio">
          <h2 className="mt-5 mb-5">Notifications</h2>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "250px", color: "#FFC400" }}
            >
              <div className="spinner-border custom-spinner" role="status"></div>
              <span className="loader-charg">Chargement</span>
            </div>
          ) : Notifications.length > 0 ? (
            Notifications.map((notif) => (
              <Link className="derectionAdmin" to={notif.url} key={notif.Date}>
                <h5>{notif.Date}</h5>
                <div className="card cardMobileNotification mb-5">
                  <span>{notif.Notification_Title}</span>
                  <div className="message">
                    <p>{notif.Notification_Content}</p>
                    <i className="bi bi-arrow-up-right-circle-fill"></i>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <h4>{messageErrosNotif}</h4>
          )}
        </div>
      </>
    );
  }

  // ----------------------- Desktop View -----------------------
  return (
    <>
      <Sidebare />
      <>
      <Strip />
      </>
      <div className="container">
        <h2 className="mt-5 mb-5">Notifications</h2>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "250px", color: "#FFC400" }}
          >
            <div className="spinner-border custom-spinner" role="status"></div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : Notifications.length > 0 ? (
          Notifications.map((notif) => (
            <Link className="derectionAdmin" to={notif.url} key={notif.Date}>
              <h5>{notif.Date}</h5>
              <div className="card mb-5">
                <span>{notif.Notification_Title}</span>
                <div className="message">
                  <p>{notif.Notification_Content}</p>
                  <i className="bi bi-arrow-up-right-circle-fill"></i>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <h4>{messageErrosNotif}</h4>
        )}
      </div>
    </>
  );
}
