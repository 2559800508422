import { Link, useParams } from "react-router-dom";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import "./ajouterClient.css"
import { MagasinContext } from "../../../Context/MagasinContext";
import { useContext, useEffect, useState } from "react";
import { ClientInfo } from "../../../Modeles/Client";
import GetClientlById from "../../../Services/Admin/GetClientById";
export interface ClientType{

  client : ClientInfo[],
  messageErrosclient:string,
}
export default function ModifierClient(){
    const magasinContext = useContext(MagasinContext);
    const MagasinId = localStorage.getItem('MagasinId');
    const idm = MagasinId || magasinContext.id?.id;
  const { id } = useParams();

    const [stateCommercial, setstateCommercial] = useState<ClientType>({
      client: [] as ClientInfo[],
        messageErrosclient: "Accune client",
      });
      useEffect(()=>{
        setstateCommercial({...stateCommercial })
          GetClientlById(id,idm).GetClient()
            .then((res)=>setstateCommercial({...stateCommercial  , client:res.data})
      
            )
            .catch(msg=>setstateCommercial({...stateCommercial  , messageErrosclient:msg.messageErrosclient}))
      },[]);
      const {client , messageErrosclient} = stateCommercial
      const handleGoBack = () => {
        window.history.back();
    };
    return<>
        <SideBareMagasin/>
        <div className="container mt-5 mb-5">
{client && client.length>0?client.map(com=>(
  <>
    <div className="barRetour">

  
      <span className="iconRetour">
        <Link to="" onClick={handleGoBack}><i className="bi bi-arrow-left-short"></i></Link>
        
      </span>
  </div>
<div className="marque">

</div>
        <div>
    <form action="" method="post">
      {/* {MessageError!="" ? 
      
      <div
        className="alert alert-danger"
        role="alert"
      >
        <h6>{MessageError}</h6>
      </div>
      :""} */}
      
  <div className="row mb-3" id="formclient">
    <div className="col">
    <span>Nom</span>
      <input type="text" name="Nom" defaultValue={com.NomClient} className="form-control"/>
    </div>
    
    <div className="col">
    <span>prénom</span>

      <input type="text" defaultValue={com.PrenomClient && com.PrenomClient ?com.PrenomClient : "N/A"}  name="Prenom" className="form-control" />
    </div>
  </div>
  <div className="row mb-3" id="formclient">

    <div className="col" >
  <span>Numéro du téléphone</span> 
      <input type="text" defaultValue={com.NumTele && com.NumTele ?com.NumTele : "N/A"}  name="Telephone"  className="form-control"  />
    </div>
    <div className="col">
  <span>Solde</span> 
  <div className="input-group mb-3">
  <input type="number" defaultValue={com.Credit} name="Credit" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span className="input-group-text" id="basic-addon2">Mad</span>
</div>

    </div>
  </div>

  <div className="row mb-3" id="formclient">
    <div className="col ">
  <span>Vile</span> 
      <input type="text" name="Ville" defaultValue={com.Ville}  className="form-control" />
    </div>
    <div className="col ">
  <span>ICE</span> 
      <input type="text" defaultValue={com.ICE && com.ICE ?com.ICE : "N/A"} name="ICE" className="form-control"/>
    </div>
    <div className="col ">
  <span>Remise</span> 
      <input type="text" defaultValue={com.RemiseGlobale && com.RemiseGlobale ?com.RemiseGlobale : "N/A"} name="ICE" className="form-control"/>
    </div>
  </div>
    <div className="btnAjouteCommercial mt-5">
      <button id="bac" className="buttonAjouter" type="submit">Modifier</button>
    </div>
</form>
</div>
</>
))
:""
}

    </div>
    </>
}