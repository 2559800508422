import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebare from '../Sidbare/Sidebare';
import logoMagasin from '../IMG/s1.png';
import './magasin.css';
import { MagasinInfo } from '../../../Modeles/MagasinModel';
import AfficheMagasinsService from '../../../Services/Magasin/AfficheMagasinsService';
import Strip from '../../../components/Strip';

export interface ProductType {
  product: MagasinInfo[];
  messageErrors: string;
}

export default function Magasin() {
  const [state, setState] = useState<ProductType>({
    product: [],
    messageErrors: 'Aucun produit',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true); // State for loader

  useEffect(() => {
    setLoading(true); // Start loading before the fetch
    AfficheMagasinsService()
      .getMagasin()
      .then((res) => {
        setState({ ...state, product: res.data });
        setLoading(false);
      })
      .catch((msg) => {
        setState({ ...state, messageErrors: msg.messageErrors });
        setLoading(false);
      });
  }, []);

  const { product, messageErrors } = state;
  console.log(product);
  // Filter the products based on the search term
  const filteredProducts = product.filter((pro) =>
    pro.NomMagasin.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  if (screenWidth <= 450) {
    return (
      <>
      <Sidebare />      
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />

<div className="container search-part position-relative mt-5 mb-3">
          <i className="bi bi-search position-absolute" style={{ top: '20%', left: '18px', transform: 'translateY(-50%)', fontSize: '16px', color: '#6c757d' }}></i>
          <input
          
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control ps-5 pe-5"
            type="text"
            placeholder="Recherchez un magasin, un Nom..."
            aria-label="search input"
          />
          
          <i className="bi bi-sliders position-absolute" style={{ top: '20%', right: '18px', transform: 'translateY(-50%)', fontSize: '16px', color: '#6c757d' }}></i>
              <Link to="/magasin/add" className="w-100 mt-3 btn btn-sm">
                Ajouter Magasin
                </Link>
        </div>
      {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "250px", color: "#FFC400" }}
            >
              <div className="spinner-border custom-spinner" role="status"></div>
              <span className="loader-charg">Chargement</span>
            </div>
          ) : 
      currentItems.length > 0 ? (
        currentItems.map((pro:any) => (
          <div key={pro.IdMagasin} className="container magasin">
            <div className="magasinInfo">
              <h5>{pro.NomMagasin}</h5>
              <img src={logoMagasin} alt="" />
            </div>
            <div className="card">
              <div className="location">
                <p>
                  <i className="bi bi-geo"></i> {pro.adresse_de_siège}
                </p>
                <div className="etoile">
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <span>(30K)</span>
                </div>
              </div>
              <p className="descmag">Depot : ---- {pro.DepotName} -----</p>
              <Link to={`/magasin/${pro.IdMagasin}/edit`}>
              <a className="voirPlus" href=""> plus de details <i className="bi bi-arrow-right-short" />
                </a>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i><br />
                <p>Malheureusement, on n‘a pas de magasin avec ce nom .</p><br />
              </div>
      )}

{pageNumbers.length > 1 && (
  <nav>
    <div className="pagination-container" style={{ marginTop: "-20%" }}>
      <ul className="pagination" style={{ marginLeft: "-5%" }}>
        {/* Previous Button */}
        <li className="page-item">
          <button
            style={{ borderRadius: "100%" }}
            className="page-button"
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="bi bi-arrow-left"></i>
          </button>
        </li>

        {/* Dynamically Rendered Page Numbers */}
        {pageNumbers.slice(0, 5).map((number) => (
          <li className="page-item" key={number}>
            <button
              className={`page-link ${
                currentPage === number ? "activeButton" : ""
              } page-number`}
              style={{
                color: "#FFC400",
                backgroundColor: "transparent",
                borderRadius: "100%",
              }}
              onClick={() => handleChangePage(number)}
            >
              {number}
            </button>
          </li>
        ))}

        {/* Next Button */}
        <li className="page-item">
          <button
            style={{ borderRadius: "100%" }}
            className="page-button"
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredProducts.length / itemsPerPage)
            }
          >
            <i className="bi bi-arrow-right"></i>
          </button>
        </li>
      </ul>
    </div>
  </nav>
)}

    </>
    )
  }

  return (
    <>
  <Sidebare />
<Strip/>
      
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div className="categ">
      <div className="container_div mt-5 rechAA" style={{ width: '100vw' }} >
          <div className="" style={{ display: 'flex', marginLeft: '8%', alignItems: "center" , marginBottom:"10%"}}>
            <div className="" style={{ width: '65vw' }}>
              <div className="position-relative">
                <i className="bi bi-search position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                <input
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control form-input pl-4"
                  placeholder="Recherch un produit, ref .."
                />
                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
              </div>
            </div>
            <div className="ml-4" style={{ marginLeft: '30px' }}>
              <Link to="/magasin/add"><button className="btnAjoutee">Ajouter Magasins</button></Link>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row height rechAA" style={{ marginLeft: '-100px' }}>
            <div className="col-md-6">
              <div className="form formMagazin">
                <i className="fa fa-search" />
                <input
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control form-inputs"
                  placeholder="Recherch un magasin, ..."
                />
                <span className="left-pan">
                  <i className="bi bi-sliders"></i>
                </span>
                <Link to="/magasin/add">
                  <button className="btnAjoutee creteMagasin">
                    Créer un magasin
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "250px", color: "#FFC400" }}
            >
              <div className="spinner-border custom-spinner" role="status"></div>
              <span className="loader-charg">Chargement</span>
            </div>
          ) : 
      currentItems.length > 0 ? (
        currentItems.map((pro:any) => (
          <div key={pro.IdMagasin} className="container magasin">
            <div className="magasinInfo">
              <h5>{pro.NomMagasin}</h5>
              <img src={logoMagasin} alt="" />
            </div>
            <div className="card">
              <div className="location">
                <p>
                  <i className="bi bi-geo"></i> {pro.adresse_de_siège}
                </p>
                <div className="etoile">
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <span>(30K)</span>
                </div>
              </div>
              <p className="descmag">Depot : ---- {pro.DepotName} -----</p>
              <Link to={`/magasin/${pro.IdMagasin}/edit`}>
              <a className="voirPlus" href=""> plus de details <i className="bi bi-arrow-right-short" />
                </a>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i><br />
                <p>Malheureusement, on n‘a pas de magasin avec ce nom .</p><br />
              </div>
      )}

      {pageNumbers.length > 1 && (
        <nav>
          <div className="pagination-container">
            <ul className="pagination" style={{ marginTop: '-20%' }}>
              <li className="page-item">
              <button
              style={{borderRadius:"100%"}}
                className="page-button"
                onClick={() => handleChangePage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <i className="bi bi-arrow-left"></i>
              </button>
              </li>
              {pageNumbers.map((number) => (
                <li className="page-item" key={number}>
                  <button
                    className={`page-link ${
                      currentPage === number ? 'activeButton' : ''
                    } page-number`}
                    style={{ color: '#FFC400', backgroundColor: 'transparent',borderRadius:"100%" }}
                    onClick={() => handleChangePage(number)}
                  >
                    {number}
                  </button>
                </li>
              ))}
              <li className="page-item">
              <button
              style={{borderRadius:"100%"}}
                className="page-button"
                onClick={() => handleChangePage(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(filteredProducts.length / itemsPerPage)
                }              >
                <i className="bi bi-arrow-right"></i>
              </button>

                
              </li>
            </ul>
          </div>
        </nav>
      )}
    </>
  );
}
