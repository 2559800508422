import axios from "axios";
import { useState } from "react";
import { ArticleInfo } from "../../Modeles/ArticleModel";

export default function AfficheProductsService(){
    const urlShowProduct = `${process.env.REACT_APP_PHP_APP_URL}/articles`;
      
    const getProduct=()=>{
      console.log(urlShowProduct);
        return (axios.get(urlShowProduct));
    }

    const getProductPagination = (page: number) => {
        return axios.get(urlShowProduct, {
          params: {
            page: page,
          },
        });
      };

    return {
        getProductPagination,
        getProduct
    }
}