import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

async function LogoutMagasin() {
    const MagasinId = localStorage.getItem('MagasinId');
    const response = await axios.get(`${process.env.REACT_APP_PHP_APP_URL}/magasins/${MagasinId}/logout`);
    if (response.data === "logout successfuly") {
      localStorage.removeItem("MagasinId");
      localStorage.removeItem("MagasinName");
      localStorage.removeItem("ClientId");
      localStorage.removeItem("ClientName");
      localStorage.removeItem("roleName");

    } else {
      console.log("Logout failed");
    }

}

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    LogoutMagasin().then(() => {
      navigate('/');
    });
  }, [navigate]); 

  return     <div className="d-flex justify-content-center align-items-center" style={{ height: '500px', color: "#FFC400" }}>
  <div className="spinner-border custom-spinner" role="status">
  </div>
      <span className="loader-charg"> Chargement..</span>
</div>;
}

export default Logout;
