import { Link } from "react-router-dom";
import Sidebare from "../Sidbare/Sidebare";
import "./historique.css";
import { FormEvent, useEffect, useState } from "react";
import HistoriqueCommande from "../../../Services/Admin/HistoriqueCommande";
import { Commande } from "../../../Modeles/Commande";
import Strip from "../../../components/Strip";
import axios from "axios";

export interface commandeType {
  product: Commande[];
  messageErros: string;
}

export default function Historique() {
  const [state, setState] = useState<commandeType>({
    product: [] as Commande[],
    messageErros: "Aucune commande",
  });
  const [Message, setMessage] = useState('');
  
  const handleSubmit = async (e:FormEvent,idCommande:any) => {
    const currentDate = new Date();
    e.preventDefault();
    const formData = new FormData();
    formData.append("idCommande", idCommande);
  console.log(formData)

  try {
    const response = await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/commande/confirme`, formData);
    console.log("back",response.data);
    setMessage('Vous avez confirmé avec succès la commande');
    setTimeout(() => {
      setMessage(''); 
    }, 2000);
  } catch (error) {
    console.error('Error:', error);
  }
  };



  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value);
  };
  useEffect(() => {
    HistoriqueCommande()
      .getCommande()
      .then((res) => setState({ ...state, product: res.data }))
      .catch((msg) => setState({ ...state, messageErros: msg.messageErros }));
  }, []);
  const { product, messageErros } = state;
  // Filter products based on search term and status filter
  const filteredProducts = product.filter((p) =>
    p.NomMagasin.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (statusFilter === "" || p.Statut === statusFilter)
  );
  // Pagination calculation
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (  
    <>
      <Sidebare />
<Strip/>
      <div className="container">
        <h4 className="mt-5">Historique des commandes</h4>
        {
          Message!=""?
        <div className="alert alert-success mt-3"role="alert">
          <h5><i className="bi bi-check-circle-fill"></i> {Message}</h5>
        </div>
        :
        ""
        }
        <div className="filtreElement mb-5">
          <Link to="" className="btnFilterS">
            <a>Cette semaine</a>
          </Link>
          <Link to="" className="btnFilterM">
            <a>Ce mois</a>
          </Link>
        </div>
        <div className="table-container mt-5">
          <div className="table-controls">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search by designation..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <i style={{position:"absolute" , fontSize:"1.3rem", marginLeft:"-5%", marginTop:"0.7%"}} className="bi bi-search"></i>
            </div>
            <select className="select-etat" onChange={handleStatusChange} value={statusFilter}>
              <option value="">Tous</option>
              <option value="confirmé">Confirmé</option>
              <option value="en cours..">En cours</option>
              <option value="annuler">Annuler</option>
            </select>
            <select className="select-etat">
              <option value="">Tous</option>
              <option value="Magasin">Magasin</option>
              <option value="Commercial">Commercial</option>
              <option value="Client">Client</option>
            </select>
          </div>
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Nom Magasin</th>
                <th>Total</th>
                <th>Statut</th>
                <th>Voir</th>
                <th>Date</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>#{item.IdCommande}</td>
                    <td>{item.NomMagasin}</td>
                    <td>{item.TotalCommandeHT} MAD</td>
                    <td><span className="s-tab-h" style={{
    color: item.Statut === 'confirmé' ? '#1fa750' : item.Statut === 'annuler' ? '#df2222' : "#cfa00c",
    backgroundColor: item.Statut === 'confirmé' ? '#cff6dd' : item.Statut === 'annuler' ? '#ffc9c9' : "#f3e9c8"
}}><b style={{fontSize:"3rem"}}> .</b> {item.Statut}</span></td>
                    <td>
                      <Link className="vdbtn" to={`/historiques/${item.IdCommande}`}>
                        Voir detail <i className="bi bi-eye-fill"></i>
                      </Link>
                    </td>
                    <td>{item.DateCommande}</td>
                    <td>
                      <Link to={``}>
                        <a href="" className="btn btn-success mb-1" onClick={(e) => handleSubmit(e, item.IdCommande)}
                        >
                          Confirmer
                        </a>
                      </Link>
                      <br />
                      <Link to={``}>
                        <a href="" className="btn btn-danger">Annuler</a>
                      </Link>
                    </td>
                    <td>
                      <Link to={`/historiques/${item.IdCommande}/rembourser`}>
                        <a href="" className="remborser">Se rembourser</a>
                      </Link>
                      <br />
                      <Link to={`/historiques/${item.IdCommande}/echanger`}>
                        <a href="" className="echanger">Echanger</a>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>{messageErros}</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination mb-5" style={{marginRight:"40px"}}>
            <button
              onClick={() => handleChangePage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Précédente
            </button>
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handleChangePage(number)}
                className={currentPage === number ? "active" : ""}
              >
                {number}
              </button>
            ))}

            <button
              onClick={() => handleChangePage(currentPage + 1)}
              disabled={currentPage === pageNumbers.length}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
