import { Link } from "react-router-dom";
import "./ajouterProduit.css"
import Sidebare from "../../Admin/Sidbare/Sidebare";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import * as XLSX from "xlsx";
import axios from "axios";
import { useState } from "react";
const AjouterProduit:React.FC<ArticleInfo> = ({
  setDesignation,
  setPrixVenteArticleTTC,
  setDescription,
  setstock,
  setRefARticle,
  handleImage,
  handleSubmit,
  setUnite,
  messageError,
  }) => {
    const [columns, setColumns] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [MessageSucess, setMessageSucess] = useState<string>("");

  const expectedColumns = ["IdArticle", "Designation", "RefArticle", "PrixVenteArticleTTC" , "image" , "stock" , "réduction"];
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onload = async (event) => {
        const data = event.target?.result;
        if (data) {
          try {
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

              let DataAricle = XLSX.utils.sheet_to_json(sheet);
            DataAricle = DataAricle.map((article: any) => ({
              ...article,
              image: article.image ?? null, 
              réduction: article.réduction ?? 0,
              stock: article.stock ?? 0,
            }));
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            if (jsonData.length > 0) {
              const uploadedColumns = jsonData[0] as string[]; 
              setColumns(uploadedColumns);
  
              const allColumnsMatch = expectedColumns.every((col) =>
                uploadedColumns.includes(col)
              );
              if (!allColumnsMatch) {
                const missingColumns = expectedColumns.filter(
                  (col) => !uploadedColumns.includes(col)
                );
                setErrorMessage(
                  `Vous avez oublié la colonne : (${missingColumns.join(
                    ", "
                  )}) Veuillez saisir toutes les colonnes correctement.`
                );
                setTimeout(() => {
                  setErrorMessage('');
              }, 3000);
                return; 
              }
            }
            const response = await axios.post(
              `${process.env.REACT_APP_PHP_APP_URL}/upload-excel-aricles`,
              DataAricle
            );
  
            if (response.status === 200) {
              const { updates, creates, duplicates } = response.data;
  
              const updateMessage =
                updates > 0 ? `${updates} articles mis à jour avec succès.` : "";
              const createMessage =
                creates > 0 ? `${creates} article créés avec succès.` : "";
  
                if (duplicates.length > 0) {
                  setErrorMessage(
                    `Un article en double a été trouvé et ignoré IdArticle :  ${duplicates[0].IdArticle}`
                  );
                  setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
                }
  
              setMessageSucess(`${updateMessage} ${createMessage}`.trim());
              setTimeout(() => {
                setMessageSucess('');
            }, 3000);
            }
          } catch (error: any) {

            if (error.response && error.response.status === 400) {
              const duplicateArticles = error.response.data.duplicates;
              setErrorMessage(
                `Erreur: Des articles en double ont été trouvés dans le fichier. Veuillez corriger les doublons: ${JSON.stringify(
                  duplicateArticles
                )}`
                
              );
              setTimeout(() => {
                setErrorMessage('');
            }, 3000);
            } else {
              console.error("Error uploading data:", error);
              alert("Erreur lors du téléchargement des données.");
            }
          }
        }
      };
  
      reader.readAsBinaryString(file);
    }
  };
    const handleGoBack = () => {
      window.history.back();
  };
    return (<>
    <Sidebare/>
    <div className="container mt-5">
<form action="" method="post" onSubmit={handleSubmit} >
    <div className="barRetour">
      <span className="iconRetour">
        <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
        
      </span>
  </div>
<div className="marque">
<h4 className="mb-3">Ajouter un nouveau produit</h4>
<div className="button-wrapper">
            <span className="label">Importer Excel </span>
            <input
              type="file"
              accept=".xlsx, .xls"
              name="upload"
              id="upload"
              className="upload-box"
              placeholder="Upload File"
              onChange={handleFileUpload}
            />
          </div>
</div>
<div className="row" style={{marginTop:"-80px"}}>
{errorMessage!="" ?
    <div style={{fontFamily:"fantasy"}} className="alert alert-danger mt-2" role="alert">
 <i className="bi bi-exclamation-triangle-fill"></i> {errorMessage}
</div>
:""}
{MessageSucess!="" ?
    <div style={{fontFamily:"fantasy"}} className="alert alert-success mt-2" role="alert">
  <i className="bi bi-check2-circle"></i> {MessageSucess}
</div>
:""}
{messageError!="" ?
    <div style={{fontFamily:"fantasy"}} className="alert alert-danger mt-2" role="alert">
  {messageError}
</div>
:""}
  <div className="col inputs">
    <div className="col-6">
        <span>Nom de produit</span>
    <input onChange={(e) => setDesignation(e.target.value)} type="text" className="form-control mb-3" placeholder="Nom article" id="nom" />
  </div>
  <div className="col-9 form-floating">
  <span className="spandesc">Description</span>

  <textarea onChange={(e) => setDescription(e.target.value)} className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: 100}} defaultValue={""} />
  <span className="nwrite">0/1000</span>

</div>

  <div className="col-6">
        <span>Categorie</span>
    <input type="text " className="form-control mb-3"  id="nom" />
  </div>
  <div className="row mt-3" >
    <div className="col">
    <span className="">Qunatité </span>

      <input onChange={(e) => setstock(e.target.value)} type="text" className="form-control"  />
    </div>

    <div className="col">
    <span className="">Réference </span>
      <input  onChange={(e) => setRefARticle(e.target.value)} type="text" className="form-control" />
    </div>
</div>
    </div>
    <div className="col">
        <div className="textUp text-center ">
        <span className="spanPP">Photo de produit </span>
        </div><br />
        <div className="photoC">

    <input onChange={handleImage}  type="file"  id="ImportPhotos" />
    <label className="photoIploade1" htmlFor="ImportPhotos" ><span><i className="bi bi-images"></i></span> <br /> Importe une photo de produit</label>       
        </div>

   <div id="wiconbtn" className="row mt-3">
    <div className="col">
    <span className="">Prix </span>

      <input name="PrixVenteArticleTTC" onChange={(e) => setPrixVenteArticleTTC(e.target.value)} type="text" className="form-control"  />
    </div>
    <div className="col">
    <span className="">Réduction </span>
      <input name="Unite" onChange={(e) => setUnite(e.target.value)} type="Number" className="form-control"  />
    </div>
    <div className="col">
    <span className="">Unité </span>
      <input name="Unite" onChange={(e) => setUnite(e.target.value)} type="Number" className="form-control"  />
    </div>
      <br />
      <div className="parent-button">
      <button className="btn-ajouter-produit" type="submit">Confirmer</button>
        </div>
</div>

    </div>
    </div>
    </form> 
    </div> 
    </>
     )
}
export default AjouterProduit;