import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

const ProtectedForMagasin: React.FC = () => {
  const { id } = useParams<{ id: string }>(); 
  const allowedMagasin = localStorage.getItem("MagasinId"); 

  if (id !== allowedMagasin) {
    return <Navigate to="/404" replace />;
  }

  return <Outlet />; 
};

export default ProtectedForMagasin;