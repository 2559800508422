import React, { useEffect, useState } from 'react';
import './NotFound.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { gsap, Linear } from 'gsap';

 
export default function NotFound (){

  useEffect(() => {
    // GSAP Animations
    const t1 = gsap.timeline();
    const t2 = gsap.timeline();
    const t3 = gsap.timeline();

    t1.to('.cog1', {
      transformOrigin: '50% 50%',
      rotation: '+=360',
      repeat: -1,
      ease: Linear.easeNone,
      duration: 8,
    });

    t2.to('.cog2', {
      transformOrigin: '50% 50%',
      rotation: '-=360',
      repeat: -1,
      ease: Linear.easeNone,
      duration: 8,
    });

    t3.fromTo(
      '.wrong-para',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
        stagger: {
          repeat: -1,
          yoyo: true,
        },
      }
    );

    // Cleanup animations on unmount
    return () => {
      t1.kill();
      t2.kill();
      t3.kill();
    };
  }, []);
  const handleGoBack = () => {
    window.history.back();
};
  return<>

  <div className="container-notfound">
    <div className="bar-retour-found">
    <i onClick={handleGoBack} className="bi bi-arrow-left-short arrow"></i>
    </div>
      <h1 className="first-four">4</h1>
      <div className="cog-wheel1">
        <div className="cog1">
          <div className="top-notfound"></div>
          <div className="down-notfound"></div>
          <div className="left-top"></div>
          <div className="left-down"></div>
          <div className="right-top"></div>
          <div className="right-down"></div>
          <div className="left-notfound"></div>
          <div className="right-notfound"></div>
        </div>
      </div>

      <div className="cog-wheel2">
        <div className="cog2">
          <div className="top-notfound"></div>
          <div className="down-notfound"></div>
          <div className="left-top"></div>
          <div className="left-down"></div>
          <div className="right-top"></div>
          <div className="right-down"></div>
          <div className="left-notfound"></div>
          <div className="right-notfound"></div>
        </div>
      </div>

      <h1 className="second-four">4</h1>
      <p className="wrong-para">Désolé, la page n'a pas été trouvée ! <i className="bi bi-emoji-dizzy"></i> </p>
    </div>
  </>
}
