import { Link } from "react-router-dom";
import Sidebare from "../Sidbare/Sidebare";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { Libraries, LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { useRef, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";

const CreateMagasin: React.FC<ArticleInfo> = ({
  messageError,
  email,
  setemail,
  message,
  NomMagasin,
  tele,
  adresse,
  fax,
  Nom_complet_propriétaire,
  image,
  setMessageError,
  setMessage,
  setNomMagasin,
  settele,
  setadresse,
  setfax,
  setNom_complet_propriétaire,
  setImage,
  handleImage,
  handleSubmit,
}) => {
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const libraries: Libraries = ["places"];
  const handlePlaceChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const formattedAddress = place.formatted_address || "";
      setadresse(formattedAddress);
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
      }
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload =async  (event) => {
        const data = event.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const DataMagasin = XLSX.utils.sheet_to_json(sheet);  
          console.log(DataMagasin);
          alert("Excel file imported successfully!");
          try {
            const response = await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/upload-excel`, DataMagasin);
            if (response.status === 200) {
              alert("Data uploaded successfully!");
            }
          } catch (error) {
            console.error("Error uploading data:", error);
            alert("Error uploading data.");
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  };
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  
  if (screenWidth <= 450) {

  return (
    <>
      <Sidebare />
      <div className="container mt-5">
        <div className="barRetour">
          <span className="iconRetour">
            <Link to="/magasin">
              <i className="bi bi-arrow-left-short"></i>
            </Link>
          </span>
        </div>
        <div className="marque">
          <h4 style={{ fontSize:"1.1rem"}}>Créer un nouveau magasin</h4>
          <div className="button-wrapper" style={{ scale:"0.81" , marginTop:"-3%"}}>
            <span className="label">Importer Excel </span>
            <input
              type="file"
              accept=".xlsx, .xls"
              name="upload"
              id="upload"
              className="upload-box"
              placeholder="Upload File"
              onChange={handleFileUpload}
            />
          </div>
        </div>
        {messageError !== "" && (
          <div className="alert alert-danger" role="alert">
            {messageError}
          </div>
        )}
        {message !== "" && (
          <div className="alert alert-success" role="alert">
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
        <div className="container-fluid">
  <div
    className="row row-mobile-add-magasin"
    style={{ marginTop: "-50px", marginLeft: "-60%" }}
  >
    {/* First Column: Logo Upload */}
    <div className="col-12 col-md-6 text-center mb-4">
      <div className="textUp">
        <span>Logo de magasin</span>
      </div>
      <br />
      <input onChange={handleImage} type="file" name="" id="ImportPhoto" />
      <label className="photoIploade" htmlFor="ImportPhoto" style={{marginLeft:"160px"}}>
        <span>
          <i className="bi bi-images"></i>
        </span>
        <br />
        Résolution minimale <br /> 250px * 250px
      </label>
      <br />
    </div>

    {/* Second Column: Form Fields */}
    <div className="col-12 col-md-6 inputs">
      <div className="mb-3">
        <span>Nom de magasin</span>
        <input
          onChange={(e) => setNomMagasin(e.target.value)}
          type="text"
          className="form-control"
          style={{width:"75%"}}
        />
      </div>
      <div className="mb-3">
        <span>Nom complet de propriétaire</span>
        <input
          onChange={(e) => setNom_complet_propriétaire(e.target.value)}
          type="text"
          style={{width:"75%"}}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <span>E-mail</span>
        <input
          onChange={(e) => setemail(e.target.value)}
          type="email"
          className="form-control"
          style={{width:"75%"}}
        />
      </div>
      <div className="mb-3">
        <span>Numéro de téléphone</span>
        <input
          onChange={(e) => settele(e.target.value)}
          type="tel"
          className="form-control"
          style={{width:"75%"}}
        />
      </div>
      <div className="mb-3">
        <span>Localisation</span>
        <LoadScript googleMapsApiKey="YOUR_GOOGLE_API_KEY" libraries={libraries}>
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Entrez votre adresse"
              ref={inputRef}
              style={{width:"75%"}}
              />
          </StandaloneSearchBox>
        </LoadScript>
      </div>
    </div>

    {/* Submit Button */}
    <div className="col-12 text-center mt-4">
      <button type="submit" className="btn-confirme-ama-mobile">
        Confirmer
      </button>
    </div>
  </div>
</div>

        </form>
      </div>
    </>
  );
};

return (
  <>
  <Sidebare />
  <div className="container mt-5">
    <div className="barRetour">
      <span className="iconRetour">
        <Link to="/magasin">
          <i className="bi bi-arrow-left-short"></i>
        </Link>
      </span>
    </div>
    <div className="marque">
      <h4>Créer un nouveau magasin</h4>
      <div className="button-wrapper">
        <span className="label">Importer Excel </span>
        <input
          type="file"
          accept=".xlsx, .xls"
          name="upload"
          id="upload"
          className="upload-box"
          placeholder="Upload File"
          onChange={handleFileUpload}
        />
      </div>
    </div>
    {messageError !== "" && (
      <div className="alert alert-danger" role="alert">
        {messageError}
      </div>
    )}
    {message !== "" && (
      <div className="alert alert-success" role="alert">
        {message}
      </div>
    )}
    <form onSubmit={handleSubmit}>
      <div className="row" style={{ marginTop: "-50px" }}>
        <div className="col inputs">
          <div className="col-6">
            <span>Nom de magasin</span>
            <input
              onChange={(e) => setNomMagasin(e.target.value)}
              type="text"
              className="form-control mb-3"
              id="nom"
            />
          </div>
          <div className="col-6">
            <span>Nom complet de proprietaire</span>
            <input
              onChange={(e) => setNom_complet_propriétaire(e.target.value)}
              type="text"
              className="form-control mb-3"
              id="nom"
            />
          </div>
          <div className="col-6">
            <span>E-mail</span>
            <input
              onChange={(e) => setemail(e.target.value)}
              type="text"
              className="form-control mb-3"
              id="nom"
            />
          </div>
          <div className="col-6">
            <span>Numéro de téléphone</span>
            <input
              onChange={(e) => settele(e.target.value)}
              type="text"
              className="form-control mb-3"
              id="nom"
            />
          </div>
          <div className="col-6 hero">
            <span>Localisation</span>
            <LoadScript
              googleMapsApiKey="AIzaSyBmLZdgzjt2mfMeAb-iZTkUeNYWqPU1FoE"
              libraries={libraries}
            >
              <StandaloneSearchBox
                onLoad={(ref) => (searchBoxRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Enter your address"
                  ref={inputRef}
                  style={{ width: "95%" }}
                />
              </StandaloneSearchBox>
            </LoadScript>
          </div>
        </div>
        <div className="col">
          <div className="textUp text-center">
            <span>Logo de magasin</span>
          </div>
          <br />
          <input onChange={handleImage} type="file" name="" id="ImportPhoto" />
          <label className="photoIploade" htmlFor="ImportPhoto">
            <span>
              <i className="bi bi-images"></i>
            </span>{" "}
            <br />
            Resolution minimal <br /> 250px * 250px
          </label>
          <br />
          <button type="submit">
            <a>Confirmer</a>
          </button>
        </div>
      </div>
    </form>
  </div>
</>
)
};


export default CreateMagasin;
